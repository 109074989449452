@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css");
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	  sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
}

.company-logo {
	width: 100%;
	// height: 64px;
	// padding: 16px;
}

// Start - Layout
.ant-layout-sider {
	position: relative;
	/* fix firefox can't set width smaller than content on flex item */
	min-width: 0;
	// background: #0CB0CF;
	background: #F2F2F2;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}
.ant-layout-sider-children {
	height: 100%;
	margin-top: -0.1px;
	padding-top: 0.1px;
}
.ant-layout-sider-has-trigger {
	padding-bottom: 48px;
}
.ant-layout-sider-right {
	-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
			order: 1;
}
.ant-layout-sider-trigger {
	position: fixed;
	bottom: 0;
	z-index: 1;
	height: 48px;
	color: #ffffff;
	line-height: 48px;
	text-align: center;
	background: #0CB0CF;
	cursor: pointer;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
	overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
	position: absolute;
	top: 64px;
	right: -36px;
	z-index: 1;
	width: 36px;
	height: 42px;
	color: #fff;
	font-size: 18px;
	line-height: 42px;
	text-align: center;
	// background: #001529;
	background: #0CB0CF;
	border-radius: 0 2px 2px 0;
	cursor: pointer;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger:hover {
	background: #192c3e;
}
.ant-layout-sider-zero-width-trigger-right {
	left: -36px;
	border-radius: 2px 0 0 2px;
}
.ant-layout-sider-light {
	background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
	color: rgba(0, 0, 0, 0.65);
	background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
	color: rgba(0, 0, 0, 0.65);
	background: #fff;
}

.ant-layout-header .ant-menu {
	line-height: inherit;
}
.ant-layout-header,
.ant-layout-footer {
	-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
			flex: 0 0 auto;
}
.ant-layout-header {
	height: 64px;
	padding: 0 50px;
	color: rgba(0, 0, 0, 0.65);
	line-height: 64px;
	// background: #F2F2F2;
	background: #ffffff;
}
.ant-layout-footer {
	padding: 24px 50px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	// background: #f0f2f5;
	background: #F2F2F2;
}
.ant-layout-content {
	-webkit-box-flex: 1;
		-ms-flex: auto;
			flex: auto;
	/* fix firefox can't set height smaller than content on flex item */
	min-height: 0;
	background: #ffffff;
}
// End - Layout

.ant-center {
	text-align: center;
}
.ant-hide {
	display: none !important;
}

.ant-page-header-heading-title {
	font-size: 1.75em;
}

.ant-radio-group {
	font-size: 1em;
	display: flex;
	flex-direction: column;
}
.ant-radio-wrapper {
	padding-bottom: 10px;
	font-size: 1.5em;
}
.ant-radio {
	vertical-align: unset;
}

.ant-checkbox-wrapper {
	font-size: 1.5em;
	padding-bottom: 10px;
	margin-left: 0 !important;
}

.ant-btn-primary {
	background: #0CB0CF;
	border-color: #0CB0CF;
}


.ant-card-head {
	color: #ffffff;
	border-bottom: none;
}


.dashboard-card {
	width: 100%;
	margin-left: 0;
	margin-right: 0;
	padding: 0;
	border-radius: 15px;
}
.dashboard-typography {
	font-weight: bold;
	font-size: 1.1em;
}
.dashboard-passed {
	color: green !important;
}
.dashboard-failed {
	color: red !important;
}
.ant-progress-inner {
	width: 60px !important;
	height: 60px !important;
	font-size: 1em !important;
}


.challenge-container {
	padding: 0;
}
.challenge-card {
	width: 100%;
	border-radius: 15px;
	background-color: #0CB0CF;
	text-align: center;
}
.challenge-question-section {
	text-align: left;
}
.challenge-question {
	font-size: 1.35em;
	user-select: none;
	max-width: 600px;
}

.challenge-question pre {
	white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.challenge-answer-section {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	text-align: left;
}
// .challenge-answer {
// 	font-size: 1.5em;
// }
.challenge-button {
	font-size: 1.25em;
	height: 45px;
	vertical-align: middle;
}
.challenge-result-card {
	border: 1px solid black;
	border-radius: 15px;
}
.challenge-result-answers {
	font-size: 36px;
}


@media (min-width: 768px) {
	.ant-progress-inner {
		width: 120px !important;
		height: 120px !important;
		font-size: 2em !important;
	}
}

@media (min-width: 1280px) {
	.ant-page-header-heading-title {
		font-size: 2em;
	}
	
	.dashboard-card {
		width: 50%;
		margin-left: auto;
		margin-right: auto;
	}
	.dashboard-typography {
		font-size: 1.25em;
	}
	
	.challenge-container {
		padding: 0 30%;
	}
}